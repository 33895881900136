import React, { useEffect, useState, useRef } from "react";
import L, { bounds, marker } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { connect } from "react-redux";
import {
  updateSelectedRoute,
  updateShowRoutesMob,
} from "../../redux/navigation/navState";
import { updateShowLocation } from "../../redux/reverse/reverseState";
import { update } from "../../redux/reverse/reverseState";
import "leaflet-geometryutil";
import { handleRouteCoordinates } from "./RouteHandler";

const myIcon = L.icon({
  iconUrl: `data:image/svg+xml;base64,${btoa(
    renderToStaticMarkup(<FaMapMarkerAlt style={{ color: "red" }} />)
  )}`,
  iconSize: [20, 75],
  iconAnchor: [10, 37.5], // Adjusted icon anchor
  popupAnchor: [0, -15],
});

const customIcon = L.icon({
  iconUrl: `data:image/svg+xml;base64,${btoa(
    renderToStaticMarkup(<MdLocationOn style={{ color: "black" }} />)
  )}`,
  iconSize: [23, 80],
  iconAnchor: [11.5, 40], // Adjusted icon anchor
  popupAnchor: [0, -20],
});

const polylines = [];
let dottedLines = [];
const decorators = [];
const MyMap = ({
  coordinates,
  routeCoordinates,
  markerCoordinates,
  updateSelectedRoute,
  resultClicked,
  directionClicked,
  updateShowRoutesMob,
  showSteps,
  showRoutesMob,
  update,
  updateShowLocation,
  label,
}) => {
  const mapRef = useRef(null);
  const markerInstances = useRef([]); // Create a separate array for marker instances
  const polylineRef = useRef(null);
  const [clickedMarker, setClickedMarker] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 639);
  const labelRef = useRef(label);

  let height;
  if (isMobile && resultClicked && !directionClicked) {
    height = "calc(2.2/4 * 100dvh)";
  } else if (showRoutesMob.polylineDrawn && isMobile && !showSteps) {
    height = "calc(3.2/4 * 100dvh)";
  } else if (showSteps && isMobile) {
    height = "0dvh";
  } else {
    height = "100dvh";
  }
  const handleResize = () => {
    setIsMobile(window.innerWidth < 639);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // useEffect(() => {
  //   console.log('Updated label:', label);
  //   let returnedMarker=null
  //   if(returnedMarker){
  //     mapRef.current.removeLayer(returnedMarker);
  //     updateShowLocation(false);
  //     returnedMarker=null
  //   }else if(label && label.coordinates && label.coordinates.length > 1){
  //     setLabel(label)
  //     // returnedMarker=L.marker([label.coordinates[1], label.coordinates[0]], { icon: myIcon }).addTo(mapRef.current)
  //     // returnedMarker.bindPopup(`Latitude: ${label.coordinates[1]}<br>Longitude: ${label.coordinates[0]} Returned coordinates`, { autoClose: false }).openPopup();
  //   }

  // }, [label]);

  useEffect(() => {
    labelRef.current = label;
  }, [label]);
  const polylineClickedRef = useRef(false);
  useEffect(() => {
    const { L } = window;

    if (!mapRef.current) {
      mapRef.current = L.map("map").setView([9.0142, 38.7872], 13);
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '© <a href="http://www.openstreetmap.org">OpenStreetMap</a>',
      }).addTo(mapRef.current);

      // Remove the default zoom control
      mapRef.current.removeControl(mapRef.current.zoomControl);

      const zoomControl = L.control.zoom({ position: "bottomleft" });
      zoomControl.addTo(mapRef.current);

      polylineRef.current = L.polyline([], { color: "blue" }).addTo(
        mapRef.current
      );

      mapRef.current.on("click", (event) => {
        // Check if a polyline was clicked; if so, don't trigger the map click event
        if (!polylineClickedRef.current) {
          const { lat, lng } = event.latlng;
          update({ latitude: lat, longitude: lng });
        }

        // Reset the flag
        polylineClickedRef.current = false;
      });
    }
    // Check if label has coordinates and place a marker initially
    if (label.coordinates && label.coordinates.length > 1) {
      if (clickedMarker) {
        mapRef.current.removeLayer(clickedMarker);
        setClickedMarker(null);
        updateShowLocation(false);
      } else {
        const newMarker = L.marker(
          [label.coordinates[1], label.coordinates[0]],
          { icon: myIcon }
        ).addTo(mapRef.current);
        // const specificZoomLevel = 15; // Replace with the desired zoom level
        // mapRef.current.setZoom(specificZoomLevel);
        // newMarker.bindPopup(`Latitude: ${label.coordinates[1]}<br>Longitude: ${label.coordinates[0]} Returned coordinates`, { autoClose: false }).openPopup();
        setClickedMarker(newMarker);
        updateShowLocation(true);
      }
    }
    // Update the marker's position if search state changes
    if (coordinates.length === 2) {
      // Clear existing markers
      markerInstances.current.forEach((marker) => {
        marker.remove();
      });

      markerInstances.current = [];

      if (polylines.length > 0) {
        polylines.forEach((polyline) => {
          polyline.remove();
        });
        polylines.length = 0; // Clear the array
      }

      // Clear existing dotted lines
      if (dottedLines.length > 0) {
        dottedLines.forEach((line) => {
          console.log(line);
          line.remove();
        });
        dottedLines = [];
      }

      if (decorators.length > 0) {
        decorators.forEach((decorator) => {
          decorator.remove();
        });
        dottedLines = [];
      }

      const newLatLng = L.latLng(coordinates[0], coordinates[1]);
      if (markerInstances.current.length === 0) {
        const marker = L.marker(newLatLng, { icon: myIcon }).addTo(
          mapRef.current
        );
        markerInstances.current.push(marker);
      } else {
        markerInstances.current[0].setLatLng(newLatLng);
      }

      // Zoom in and center the map to fit the new marker
      const bounds = L.latLngBounds([newLatLng]);
      mapRef.current.fitBounds(bounds);
    }
    // Update the polyline and markers when routeCoordinates change

    //Navigation
    handleRouteCoordinates(
      mapRef,
      markerInstances,
      polylines,
      polylineClickedRef,
      updateShowRoutesMob,
      updateSelectedRoute,
      markerCoordinates,
      routeCoordinates,
      dottedLines,
      decorators
    );
  }, [coordinates, routeCoordinates, markerCoordinates, label]);

  return (
    <div
      id="map"
      style={{
        height: height,
        zIndex: 0, // Make sure the z-index is set to a value lower than AppSearch
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    ...state.search,
    ...state.navigation,
    ...state.directions,
    ...state.reverseSlice,
  };
}

const mapDispatchToProps = {
  updateSelectedRoute: updateSelectedRoute,
  updateShowRoutesMob: updateShowRoutesMob,
  update: update,
  updateShowLocation: updateShowLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMap);
