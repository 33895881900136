import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState:{
       idToken: localStorage.getItem('publicToken'),
       loggedInUser: {},
       error:null,
       signupSuccess: false,
       signinSuccess:false,
    },
    reducers:{
        signup: (state, action) => { 
        },
        signupSuccess:(state, action) =>{
          state.signupSuccess = true;
        },
        update:(state, action) =>{
          state.signupSuccess = false
        },
        update2:(state, action) =>{
          state.signinSuccess = false
        },
        login: (state, action) => { 
        },
        googleLogin: (state, action) => { 
        },
        facebookLogin: (state, action) => { 
        },
        getLoggedInUser:(state,action) => {
        },
        getLoggedInUserSuccess:(state,action) => {
          state.loggedInUser = action.payload
        },
        loginSuccess: (state, action) => {
            state.idToken = action.payload.token
            state.error = null
            state.signinSuccess=true;
        },
        loginFailure: (state, action) => {
            state.idToken = null;
            state.error = action.payload; // Set the error message in the state
          },
        logout: (state) => {
           
          },
        logoutSuccess: (state) => {
            state.idToken = null;
            state.error = null;
          },
    }
});

export const {login,logout,signup,facebookLogin,update,update2,googleLogin,loginSuccess,signupSuccess,signupSuccesslogout,loginFailure,logoutSuccess,getLoggedInUser,getLoggedInUserSuccess} = authSlice.actions;
export default authSlice.reducer;