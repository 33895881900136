import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import { updatePlaceData } from "./placeState";
import { notification } from "antd";
import jwt_decode from "jwt-decode";

const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};

function* workAddPlace(action) {
  const { token, values } = action.payload;
  let coordinatesArray = values.coordinates
    .split(",")
    .map((coord) => parseFloat(coord));

  try {
    const userData = {
      userId: jwt_decode(token).id,
      coordinates: coordinatesArray,
      properties: {
        id: "node/11034209868",
        gid: "openstreetmap:venue:node/11034209866",
        layer: "venue",
        source: "openstreetmap",
        source_id: "node/11034209866",
        country_code: "ET",
        name: "Som Kilo",
        confidence: 1,
        match_type: "exact",
        distance: 189.95,
        accuracy: "point",
        country: "Ethiopia",
        country_gid: "whosonfirst:country:85632257",
        country_a: "ETH",
        region: "Addis Ababa",
        region_gid: "whosonfirst:region:85671149",
        region_a: "AA",
        county: "Addis Ababa Zone 1",
        county_gid: "whosonfirst:county:1108695109",
        county_a: "ON",
        locality: "Addis Ababa",
        locality_gid: "whosonfirst:locality:421170391",
        continent: "Africa",
        continent_gid: "whosonfirst:continent:102191573",
        label: `${values.placeName}`,
        addendum: {
          osm: {
            operator: `${values.placeName}`,
          },
        },
      },
    };

    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_PLACES_URL}places`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    openNotificationWithIcon("success", "Place created successfully!");
  } catch (error) {
    openNotificationWithIcon("error", "Error creating place");
  }
}

function* workEditPlace(action) {
  const { token, values, place } = action.payload;
  let coordinatesArray = values.coordinates
    .split(",")
    .map((coord) => parseFloat(coord));
  try {
    const userData = {
      userId: jwt_decode(token).id,
      coordinates: coordinatesArray,
      properties: {
        id: "node/11034209868",
        gid: "openstreetmap:venue:node/11034209866",
        layer: "venue",
        source: "openstreetmap",
        source_id: "node/11034209866",
        country_code: "ET",
        name: "Som Kilo",
        confidence: 1,
        match_type: "exact",
        distance: 189.95,
        accuracy: "point",
        country: "Ethiopia",
        country_gid: "whosonfirst:country:85632257",
        country_a: "ETH",
        region: "Addis Ababa",
        region_gid: "whosonfirst:region:85671149",
        region_a: "AA",
        county: "Addis Ababa Zone 1",
        county_gid: "whosonfirst:county:1108695109",
        county_a: "ON",
        locality: "Addis Ababa",
        locality_gid: "whosonfirst:locality:421170391",
        continent: "Africa",
        continent_gid: "whosonfirst:continent:102191573",
        label: `${values.placeName}`,
        addendum: {
          osm: {
            operator: `${values.placeName}`,
          },
        },
      },
    };
    const response = yield call(() =>
      axios.put(
        `${process.env.REACT_APP_PLACES_URL}places/${place.id}`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    );
    openNotificationWithIcon("success", "Place edited successfully!");
  } catch (error) {
    openNotificationWithIcon("error", "Error editing place");
  }
}

function* getPlace(action) {
  const { token } = action.payload;
  try {
    const userId = jwt_decode(token).id;

    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_PLACES_URL}myPlaces/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    );
    yield put(updatePlaceData(response.data)); // Dispatch action to update myPlace state
  } catch (error) {
    // Dispatch an action to handle errors.
    // yield put(getPlaceFailure(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery("place/addPlace", workAddPlace)]);
  yield all([takeEvery("place/getPlace", getPlace)]);
  yield all([takeEvery("place/editPlace", workEditPlace)]);
}
