import { createSlice } from "@reduxjs/toolkit";

export const directionSlice = createSlice({
    name: 'directions',
    initialState:{
        showSteps:false,//sessionStorage.getItem('showSteps')==='true'
        steps:[],
        routes:[]
    },
    reducers:{
        update: (state, action) => {
            state.showSteps = !state.showSteps;
        },
        updateSteps:(state, action) => {
            state.steps = action.payload;
        },
        updateRoutes:(state, action) => {
            state.routes = action.payload;
        },
    }
});

export const {update,updateSteps,updateRoutes} = directionSlice.actions;
export default directionSlice.reducer;
