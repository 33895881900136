import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import { updateResetPassword, updateEmailSent } from "./resetState";
import { notification } from "antd";

const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};

function* forgotPassword(action) {
  const { email } = action.payload;
  try {
    const userData = {
      email: email,
    };
    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}forgotPassword`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    openNotificationWithIcon("success", "Verification code sent!");
    yield put(updateResetPassword(true));
  } catch (error) {
    // console.log(error)
    openNotificationWithIcon("error", "Failed to send verification code");
  }
}

function* resetPassword(action) {
  const { verificationCode, email, newPassword } = action.payload;
  try {
    const userData = {
      email: email,
      verificationCode: verificationCode,
      newPassword: newPassword,
    };
    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_AUTH_URL}resetPassword`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    openNotificationWithIcon("success", "Password reset successfull!");
    yield put(updateEmailSent(false));
  } catch (error) {
    // console.log(error)
    openNotificationWithIcon("error", "Password reset failed!");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery("reset/forgot", forgotPassword),
    takeEvery("reset/reset", resetPassword),
  ]);
}
