import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    collapsed: false,
    isMobile: window.innerWidth < 639,
  },
  reducers: {
    toggleCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  }
});

export const { toggleCollapsed, setIsMobile } = appSlice.actions;
export default appSlice.reducer;
