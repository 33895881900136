import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import "leaflet/dist/leaflet.css";
import MapComponent from "./addPlaceMap";

const AddPlaceForm = () => {
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  const handleMapClick = (coordinates) => {
    setCoordinates(coordinates);
    form.setFieldsValue({
      coordinates: `${coordinates.lat}, ${coordinates.lng}`,
    });
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Form values:", values);
    // Handle form submission here
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Place Name"
          name="placeName"
          rules={[{ required: true, message: "Please enter a place name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Coordinates"
          name="coordinates"
          rules={[{ required: true, message: "Please select coordinates" }]}
        >
          <Input readOnly />
        </Form.Item>
        <MapComponent id="mapForm" onMapClick={handleMapClick} />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Place
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddPlaceForm;
