import React, { useState } from "react";
import { Form, Input, Button, Modal, Image, Upload } from "antd";
import { connect } from "react-redux";
import { addPlace, setShowContributions } from "../../redux/places/placeState";
import MapComponent from "../../containers/Places/addPlaceMap";
import { PlusOutlined } from "@ant-design/icons";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UserAvatar = ({
  handleLogout,
  username,
  firstName,
  lastName,
  addPlace,
  setShowContributions,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const initial = username ? username.charAt(0).toUpperCase() : "";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMapClick = (coordinates) => {
    setCoordinates(coordinates);
    setMarkerPosition(coordinates);
    form.setFieldsValue({
      coordinates: `${coordinates.lat}, ${coordinates.lng}`,
    });
  };

  const onFinish = (values) => {
    const token = localStorage.getItem("publicToken");
    addPlace({ values, token });
    // Handle form submission here
    setIsModalVisible(false);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div>
      <div
        className="absolute  top-5 right-3 flex mr-16 z-10 mobile:mr-3 mobile:top-1 w-11 h-11 mobile:w-9 mobile:h-9 bg-blue-500 rounded-full flex items-center 
           justify-center text-white bg-gradient-to-br from-blue-500 to-indigo-500 shadow-lg"
        onClick={toggleDropdown}
      >
        {initial}
      </div>
      {isDropdownOpen && (
        <div className="absolute z-10 flex top-12 mobile:top-6 right-0 w-64 mobile:w-48 mt-5 mr-2 bg-white shadow-md rounded-md">
          <div>
            <div className="origin-top-right absolute right-0 mr-4 w-80 rounded-md shadow-lg">
              <p className="inline-flex justify-center mt-4 text-base w-full px-2 py-4 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 rounded-t-xl shadow-sm">
                {firstName} {lastName}
              </p>
              <div className="bg-white shadow-xs rounded-b-xl pl-2">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Account settings
                  </a>
                  <p
                    onClick={showModal}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Add a missing place
                  </p>

                  <Modal
                    title={`${firstName} ${lastName}`}
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <Form form={form} onFinish={onFinish} layout="vertical">
                      <Form.Item
                        label="Place Name"
                        name="placeName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a place name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Coordinates"
                        name="coordinates"
                        rules={[
                          {
                            required: true,
                            message: "Please select coordinates",
                          },
                        ]}
                      >
                        <Input readOnly />
                      </Form.Item>
                      <Upload
                        // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                      >
                        {fileList.length >= 8 ? null : uploadButton}
                      </Upload>
                      {previewImage && (
                        <Image
                          wrapperStyle={{
                            display: "none",
                          }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )}
                      <MapComponent
                        onMapClick={handleMapClick}
                        markerPosition={markerPosition}
                      />

                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Add Place
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>

                  <p
                    onClick={() => setShowContributions(true)}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Your contributions
                  </p>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Support
                  </a>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-red-100 hover:text-red-900"
                    role="menuitem"
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {isDropdownOpen && (
      // Clicking anywhere on the document should close the dropdown
      <div
        className="fixed top-0 z-10 left-0 w-full h-full"
        onClick={closeDropdown}
      />
    )} */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ...state.place,
  };
}

const mapDispatchToProps = {
  addPlace: addPlace,
  setShowContributions: setShowContributions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);
