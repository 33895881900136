import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
    name: 'search',
    initialState:{
        coordinates:[],
        resultClicked: false, // sessionStorage.getItem('resultClicked') === 'true'
        resultLabel: ""
    },
    reducers:{
        update: (state, action) => {
            state.coordinates = action.payload
        },
        updateResultClicked: (state, action) => {
            state.resultClicked = true
            state.resultLabel = action.payload
        }
    }
});

export const {update,updateResultClicked} = searchSlice.actions;
export default searchSlice.reducer;
