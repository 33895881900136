import React, { Component } from "react";
import { Col, Row } from "antd";
import { Divider } from "antd";
import { FaWalking } from "react-icons/fa";
import "./routes.css";
import { connect } from "react-redux";
import { update, updateSteps } from "../../../redux/directions/directionState";
import {
  updateRoute,
  updateSelectedRoute,
} from "../../../redux/navigation/navState";
import { updateShowLocation } from "../../../redux/reverse/reverseState";
import { LoadingOutlined } from "@ant-design/icons";

class SideRoutes extends Component {
  renderDuration(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return hours > 0 ? `${hours} hr ${minutes} min` : `${minutes} min`;
  }

  renderDistance(distanceInMeters) {
    const kilometers = (distanceInMeters / 1000).toFixed(2);
    return `${kilometers} km`;
  }

  renderRoutes() {
    const { routes } = this.props;
    return routes.map((route, index) => (
      <div
        key={index}
        className="route-container"
        style={{
          width: "243px",
          height: "63px",
          color: "black",
          borderRadius: "8px",
          top: "520px",
          left: "38px",
          marginBottom: "6px",
          // backgroundColor: '',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "7%",
          marginTop: "1.5%",
          cursor: "pointer",
          backgroundColor:
            this.props.selectedRoute === index ? "#2A29CC" : "#F5F5F5",
          color: this.props.selectedRoute === index ? "white" : "",
        }}
        onClick={() => {
          // Decode the polyline and get directions
          const directions = route.geometry.coordinates;

          // Transform the array of directions into an object
          const directionsObject = { directions };

          // Call the Redux action to update the route with the directions
          this.props.updateRoute([directionsObject]);
          this.props.Update();
          this.props.updateSteps(route.legs[0].steps);
          this.props.updateSelectedRoute(index);
        }}
      >
        <Row className="items-center">
          <Col flex={2}>
            <Row className="items-center">
              <FaWalking
                size={20}
                color={this.props.selectedRoute === index ? "white" : "#626262"}
              />
              <Divider
                type="vertical"
                style={{ height: 50, background: "#A0A0A0" }}
              />
              <p className="font-semibold text-base my-1 ml-2">
                Route {index + 1}
              </p>
            </Row>
          </Col>
          <Col flex={5} push={5}>
            <p
              style={{
                margin: 0,
                textAlign: "right",
                padding: "0 65px 0 0",
                fontWeight: "630",
              }}
            >
              {this.renderDuration(route.duration)}
            </p>
            <p style={{ margin: 0, textAlign: "right", padding: "0 65px 0 0" }}>
              {this.renderDistance(route.distance)}
            </p>
          </Col>
        </Row>
      </div>
    ));
  }

  render() {
    const { routes, loadRoutes } = this.props;
    return (
      <div>
        {routes.length ? (
          <h1 className="font-semibold text-lg ml-7 mt-4 mb-0 text-left">
            Routes
          </h1>
        ) : null}
        {loadRoutes ? (
          <LoadingOutlined className="flex flex-col items-center justify-center text-4xl mt-5" />
        ) : (
          this.renderRoutes()
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.directions,
    ...state.navigation,
  };
}

const mapDispatchToProps = {
  Update: update,
  updateRoute: updateRoute,
  updateSteps: updateSteps,
  updateSelectedRoute: updateSelectedRoute,
  updateShowLocation: updateShowLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideRoutes);
