// RouteHandler.js

import L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import "@elfalem/leaflet-curve";
import "leaflet-polylinedecorator";

const myIcon = L.icon({
  iconUrl: `data:image/svg+xml;base64,${btoa(
    renderToStaticMarkup(<FaMapMarkerAlt style={{ color: "red" }} />)
  )}`,
  iconSize: [20, 75],
  iconAnchor: [10, 37.5], // Adjusted icon anchor
  popupAnchor: [0, -15],
});

const customIcon = L.icon({
  iconUrl: `data:image/svg+xml;base64,${btoa(
    renderToStaticMarkup(<MdLocationOn style={{ color: "black" }} />)
  )}`,
  iconSize: [23, 80],
  iconAnchor: [11.5, 40], // Adjusted icon anchor
  popupAnchor: [0, -20],
});

// Array to store dotted lines
export const handleRouteCoordinates = (
  mapRef,
  markerInstances,
  polylines,
  polylineClickedRef,
  updateShowRoutesMob,
  updateSelectedRoute,
  markerCoordinates,
  routeCoordinates,
  dottedLines,
  decorators
) => {
  const createDottedLine = (polyline, marker, mapRef) => {
    const controlPoints = calculateControlPoints(polyline, marker);
    const dottedLine = L.curve(controlPoints, {
      color: "#2A29CC",
      weight: 2,
      dashArray: "5, 5",
    }).addTo(mapRef.current);
    dottedLines.push(dottedLine);
  };

  const calculateControlPoints = (polyline, marker) => {
    console.log(polyline, marker);
    const offset = 0.2;
    const direction = polyline[0] < marker.lat ? 1 : -1;

    const dx = marker.lng - polyline[1];
    const dy = marker.lat - polyline[0];
    const px = -dy;
    const py = dx;

    const controlPoint1 = L.latLng(
      polyline[0] + (marker.lat - polyline[0]) / 3 + direction * offset * px,
      polyline[1] + (marker.lng - polyline[1]) / 3 + direction * offset * py
    );
    const controlPoint2 = L.latLng(
      polyline[0] +
        (2 * (marker.lat - polyline[0])) / 3 +
        direction * offset * px,
      polyline[1] +
        (2 * (marker.lng - polyline[1])) / 3 +
        direction * offset * py
    );

    return [
      "M",
      [polyline[0], polyline[1]],
      "C",
      [controlPoint1.lat, controlPoint1.lng],
      [controlPoint2.lat, controlPoint2.lng],
      [marker.lat, marker.lng],
    ];
  };
  if (routeCoordinates.length >= 1) {
    markerInstances.current.forEach((marker) => {
      marker.remove();
    });
    markerInstances.current = [];

    if (polylines.length > 0) {
      polylines.forEach((polyline) => {
        polyline.remove();
      });
      polylines.length = 0;
    }

    // Initialize bounds
    const bounds = L.latLngBounds();
    const reversedRouteCoordinates = [...routeCoordinates];

    // Reverse the order of the copied array
    reversedRouteCoordinates.reverse();
    let clickedPolyline = null;
    // Clear existing dotted lines
    if (dottedLines.length > 0) {
      dottedLines.forEach((line) => {
        console.log(line);
        line.remove();
      });
      dottedLines = [];
    }

    if (decorators.length > 0) {
      decorators.forEach((decorator) => {
        decorator.remove();
      });
      dottedLines = [];
    }

    reversedRouteCoordinates.forEach((route, index) => {
      // console.log(index, route);
      const latLngs = route.directions.map((coordinate) =>
        L.latLng(coordinate[1], coordinate[0])
      );
      let color;
      if (reversedRouteCoordinates.length == 1) {
        color = "#2A29CC";
      } else {
        color = index === 0 ? "#708090" : "#2A29CC";
      }
      const weight = 10;

      // Create a new polyline with the updated latLngs and color
      const polyline = L.polyline(latLngs, { color, weight }).addTo(
        mapRef.current
      );
      // Add the polyline to the array
      polylines.push(polyline);
      updateShowRoutesMob(true);
      // Extend bounds with polyline's bounds

      const decorator = L.polylineDecorator(polyline, {
        patterns: [
          {
            offset: 10,
            repeat: 30, // Adjust this value to control the density of the dots
            symbol: L.Symbol.dash({ pixelSize: 8 }),
          },
        ],
      }).addTo(mapRef.current);
      decorators.push(decorator);

      bounds.extend(polyline.getBounds());
      polylines.reverse();
      polyline.on("click", () => {
        // Set the flag to true when the polyline is clicked
        polylineClickedRef.current = true;

        const clickedIndex = polylines.indexOf(polyline);
        updateSelectedRoute(clickedIndex);
        polyline.setStyle({ color: "#2A29CC" });

        polylines.forEach((otherPolyline) => {
          if (otherPolyline !== polyline) {
            otherPolyline.setStyle({ color: "#708090" });
          }
        });

        polyline.bringToFront();
      });

      createDottedLine(
        [
          reversedRouteCoordinates[0].directions[0][1],
          reversedRouteCoordinates[0].directions[0][0],
        ],
        L.latLng(
          markerCoordinates[0].coordinates[1],
          markerCoordinates[0].coordinates[0]
        ),
        mapRef
      );

      for (let i = 1; i < markerCoordinates.length - 1; i++) {
        const middleMarkerLatLng = L.latLng(
          markerCoordinates[i].coordinates[1],
          markerCoordinates[i].coordinates[0]
        );

        // Initialize variables to track the closest point and distance
        let closestPoint = null;
        let minDistance = Number.MAX_SAFE_INTEGER;

        // Iterate over the latlngs of the polyline
        polyline.getLatLngs().forEach((latLng) => {
          const distance = middleMarkerLatLng.distanceTo(latLng);

          // Update closest point if the current point is closer
          if (distance < minDistance) {
            minDistance = distance;
            closestPoint = latLng;
          }
        });

        if (closestPoint) {
          const middleDottedLine = L.polyline(
            [closestPoint, middleMarkerLatLng],
            {
              color: "#2A29CC",
              weight: 2,
              dashArray: "5, 5",
            }
          ).addTo(mapRef.current);
          dottedLines.push(middleDottedLine);
        }
      }

      createDottedLine(
        [
          reversedRouteCoordinates[0].directions[
            reversedRouteCoordinates[0].directions.length - 1
          ][1],
          reversedRouteCoordinates[0].directions[
            reversedRouteCoordinates[0].directions.length - 1
          ][0],
        ],
        L.latLng(
          markerCoordinates[markerCoordinates.length - 1].coordinates[1],
          markerCoordinates[markerCoordinates.length - 1].coordinates[0]
        ),
        mapRef
      );
    });
    mapRef.current.fitBounds(bounds, { padding: [50, 50] });
    markerCoordinates.forEach((route, index) => {
      const markerLatLng = L.latLng(route.coordinates[1], route.coordinates[0]);
      const markerIcon =
        index === markerCoordinates.length - 1 ? myIcon : customIcon;
      const marker = L.marker(markerLatLng, { icon: markerIcon }).addTo(
        mapRef.current
      );
      markerInstances.current.push(marker);

      // Create a popup for each marker
      marker.bindPopup(route.label);
      // }
    });
  }
};
