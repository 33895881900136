import React, { Component } from 'react';
import './routes.css'; // Import the CSS file for styling
import { connect } from "react-redux";
import { update } from "../../../redux/directions/directionState";
import {
  FaLocationArrow,
} from 'react-icons/fa';
import {FiArrowLeft,FiArrowRight,FiArrowUp} from 'react-icons/fi'
import {MdTurnSharpLeft,MdTurnSharpRight,MdTurnSlightLeft,MdTurnSlightRight,MdUTurnLeft} from 'react-icons/md'

class StepsUI extends Component {
  render() {
    const { update, steps } = this.props;

    return (
      <div className="steps-container flex flex-col">
        <div className="back-button" onClick={update} style={{ cursor: 'pointer', fontWeight: '500' }}>
          Back
        </div>
        <div className=" step">
          {steps.map((step, index) => (
            <div key={index} className="step flex items-center">
              {step.maneuver && !(step.maneuver.type === 'arrive') && (
                <div className="w-6 h-6 mr-2">
                  {getManeuverIcon(step.maneuver)}
                </div>
              )}
              <div className="flex flex-col">
                <div className="place text-sm font-medium text-gray-900">
                  {!(step.maneuver.type === 'arrive') ? (step.name !== '' ? step.name : 'Unknown Road') : null}
                </div>
                {step.maneuver && step.maneuver.type === 'arrive' ? (
                  <div className="destination-text text-large font-medium text-500 text-center mt-2 ">
                  {step.maneuver.modifier ?  `Destination is on the ${step.maneuver.modifier}`:`Destination reached` }
                </div>
                ) : (
                  <div className="flex items-center space-x-2">
                    <div className="text-sm font-medium text-gray-500">{step.distance && formatDistance(step.distance)}</div>
                    <div className="text-sm font-medium text-gray-500">{step.duration && formatDuration(step.duration)}</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

function getManeuverIcon(maneuver) {

  // Handle other maneuvers with existing icons
  switch (maneuver.modifier) {
    case 'left':
      return <FiArrowLeft style={{ fontSize: '16px' }} />;
    case 'right':
      return <FiArrowRight style={{ fontSize: '16px' }} />;
    case 'straight':
      return <FiArrowUp style={{ fontSize: '16px' }} />;
    case 'slight left':
      return <MdTurnSlightLeft style={{ fontSize: '16px' }} />;
    case 'slight right':
      return <MdTurnSlightRight style={{ fontSize: '16px' }} />;
    case 'sharp left':
      return <MdTurnSharpLeft style={{ fontSize: '16px' }} />;
    case 'sharp right':
      return <MdTurnSharpRight style={{ fontSize: '16px' }} />;
    case 'uturn':
      return <MdUTurnLeft style={{ fontSize: '16px' }} />;
    // Handle 'exit rotary'
    case 'exit rotary':
      return <FaLocationArrow style={{ fontSize: '16px' }} />;
    default:
      return null;
  }
}
function formatDistance(distance) {
  // Convert to kilometers if over 1000 meters
  const distanceInKm = distance >= 999 ? (distance / 1000).toFixed(1) + ' km' : distance.toFixed(1) + ' meters';
  return distanceInKm;
}

function formatDuration(duration) {
  // Convert to minutes if greater than 59 seconds
  const durationInMinutes = duration > 59 ? (Math.round(duration / 60)).toFixed(1) + ' minutes' : duration.toFixed(1) + ' seconds';
  return durationInMinutes;
}


// function getManeuverDetails(maneuver) {
//   switch (maneuver.type) {
//     case 'depart':
//       return `Depart from the current location with a ${maneuver.modifier} turn.`;
//     case 'arrive':
//       return `Arrive at the destination with a ${maneuver.modifier} approach.`;
//     case 'turn':
//       return `Make a ${maneuver.modifier} turn.`;
//     case 'merge':
//       return `Merge onto the next road with a ${maneuver.modifier} merge.`;
//     case 'take':
//       return `Take the ${maneuver.modifier} exit.`;
//     // Add more cases as needed
//     default:
//       return `Perform a maneuver with type ${maneuver.type} and modifier ${maneuver.modifier}.`;
//   }
// }
function mapStateToProps(state) {
  return {
    ...state.directions
  };
}

const mapDispatchToProps = {
  update: update
};

export default connect(mapStateToProps, mapDispatchToProps)(StepsUI);
