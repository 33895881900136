import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Title, WelcomeText, HorizontalLine } from "../Signin/login.style";
import { Button, Form, Input } from "antd";
import { reset } from "../../../redux/reset/resetState";

const ResetPassword = ({ reset }) => {
  const onFinish = (values) => {
    reset(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex-grow w-1/2 bg-white-500 mobile:w-full mobile:h-full mb-5">
      <Title style={{ height: "9vh", color: "#131466" }}>
        ETHIO<b>MAPS</b>
      </Title>
      <WelcomeText>Reset your password.</WelcomeText>
      <HorizontalLine />
      <Form
        name="basic"
        layout="vertical"
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="px-16"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Verification Code"
          name="verificationCode"
          rules={[
            {
              required: true,
              message: "Please verification code!",
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            className="w-full text-white"
            style={{ background: "#2a29cc", marginTop: "20px" }}
            htmlType="submit"
          >
            Reset password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ...state.resetSlice,
    // ...state.search,
  };
}
const mapDispatchToProps = {
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
