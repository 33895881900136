import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { connect } from "react-redux";

const drawerBleeding = 32;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 40,
  height: 7,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top:12,
  left: 'calc(50% - 15px)',
}));

function TopSwipeableDrawer({children,showRoutesMob}) {
  // const { window } = props;
  // console.log(showRoutesMob.polylineDrawn)
  const [open, setOpen] = React.useState(true);
  // useEffect(() => {
  //   setOpen(!showRoutesMob.polylineDrawn)
  // }, [showRoutesMob.polylineDrawn]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
            overflow: 'visible',
            },
        }}
        />
      {/* <Box sx={{ textAlign: 'center', pt: 1 }}>
        <Button onClick={toggleDrawer(true)}>Open</Button>
      </Box> */}
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
            keepMounted: true,
        }}
        >
      <StyledBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          position: 'absolute',
          bottom: -drawerBleeding,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
        }}
      >
        <Typography sx={{ textAlign: 'center', marginBottom: '33px' }}>
        
        </Typography>
        <Puller />
        
        {/* <Box sx={{ flexGrow: 1 }}>
          {children}
        </Box> */}
      </StyledBox>

        {children}
        </SwipeableDrawer>

    </Root>
  );
}

TopSwipeableDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
  children: PropTypes.node,
};

function mapStateToProps(state){
  return {
    showRoutesMob: state.navigation.showRoutesMob.polylineDrawn,
    ...state.navigation
  };
}

export default connect(mapStateToProps)(TopSwipeableDrawer);

