// sidebar.js
import React, { Component } from "react";
import Logo from "../../components/utility/logo";
import AppSearch from "../Search/search";
import SideRoutes from "../routes/Search/routes";
import StepsUI from "../routes/Search/steps";
import AppNavigation from "../Navigation/navigation";
import { connect } from "react-redux";
import { toggleCollapsed } from "../../redux/app/appState";
import {updateDirectionClicked} from "../../redux/navigation/navState";
import { BiSolidDirectionRight } from "react-icons/bi";
import { AiFillStar } from 'react-icons/ai';
import { Button} from 'antd';
import place from "../../image/place.jpg";
import '../routes/Search/routes.css';


class Sidebar extends Component {
 
  render() {
    // console.log(this.props.resultLabel);
    return (
      <div className="flex" >

        {/*sidebar for mobile */}
        <div className="hidden mobile:block mobile:mt-1 w-full p-2" style={{height: 'calc(1.7/4 * 100dvh' }}>
          <img alt="user" style={{ height:'51%', width:'100%'}} src={place} />
          <p className=" font-medium " style={{fontSize:'100%', lineHeight:'110%'}} >{this.props.resultLabel}</p>
          <p className="flex items-center">
            <span style={{fontSize:'110%'}} className="mr-1">5.0</span>
            <AiFillStar
              style={{
                color: 'gold', // Golden yellow color
                fontSize:'110%',
                textShadow: '0 0 5px rgba(255, 255, 255, 0.8)', // White text shadow for a 3D effect
              }}
            />
            <span style={{fontSize:'110%', height:' 37.3%'}} className="ml-1 text-[0.9rem]">(1)</span>
          </p>

          <p className="text-[0.9rem]">Hotel</p>

          <div className="">
            <Button type="primary" 
            style={{ fontSize:'100%',backgroundColor: '#1211FF' }} 
            icon={<BiSolidDirectionRight />}
            onClick={(e)=>this.props.updateDirectionClicked(true)}>
              Direction
            </Button>
          </div>
        </div>

        {/* for Desktop */}
        <div
          className={`${
            this.props.collapsed ? "w-16" : "w-72"
          } bg-white text-black transition-all duration-300 ease-in-out overflow-hidden mobile:hidden steps-list  overflow-y-auto`} style={{height:'100dvh'}}
        >
          <Logo collapsed={this.props.collapsed}/>
          <AppSearch />
          <AppNavigation />
          {this.props.showSteps ? <StepsUI /> : <SideRoutes />}
          
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collapsed: state.app.collapsed,
    ...state.directions,
    ...state.search,
  };
}

const mapDispatchToProps = {
  toggleCollapsed,
  updateDirectionClicked,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
