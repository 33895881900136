import React, { Component } from 'react';
import { connect } from "react-redux";
import { UnorderedListOutlined } from '@ant-design/icons';
import { update} from "../../../redux/directions/directionState";
import {FaMapMarked,FaLocationArrow } from 'react-icons/fa';

import {FiArrowLeft,FiArrowRight,FiArrowUp} from 'react-icons/fi'
import {MdTurnSharpLeft,MdTurnSharpRight,MdTurnSlightLeft,MdTurnSlightRight,MdUTurnLeft} from 'react-icons/md'

class MobileView extends Component {
    renderDuration(durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        return hours>0?(`${hours} hr ${minutes} min`):(`${minutes} min`);
      }
    renderDistance(distanceInMeters) {
        const kilometers = (distanceInMeters / 1000).toFixed(2);
        return `${kilometers} km`;
      }

  render() {
    const {routes,selectedRoute, UpdateStepState} = this.props;

      return (
        <div className="bg-white p-2 h-1/8 flex flex-col" style={{height: this.props.showSteps ? '100dvh' : 'auto'}}>
          <div style={{position: ''}}>
            <div className="text-lg font-semibold">
            {routes.length !== 0 ? (
              <div className="flex items-center space-x-4">
              <span className="text-gray-700">{this.renderDuration(routes[selectedRoute].duration)}</span>
              <span className="text-gray-700">|</span>
              <span className="text-gray-700">{this.renderDistance(routes[selectedRoute].distance)}</span>
            </div>
            
            ) : null}

            </div>
            <div className="text-sm text-gray-500">Route {selectedRoute+1}</div>
            <button
              onClick={() => {
                UpdateStepState();
              }} 
              className="mt-2 bg-gray-200 text-gray-700 font-bold py-1 px-2 rounded flex items-center"
            >
              {this.props.showSteps ? (
                <>
                  <FaMapMarked className="mr-2" />
                  Map
                </>
              ) : (
                <>
                  <UnorderedListOutlined className="mr-2" />
                  Steps
                </>
              )}
            </button>
            <hr className="border-t-2 border-gray-300 my-2" />
          </div>
          <div className="overflow-y-auto h-max step flex-grow">
            {this.props.showSteps&&routes.length!==0 ? routes[selectedRoute].legs[0].steps.map((step, index) => (
                 <div class=" step z-10">
                   <div key={index} class="step flex items-center">
                     {step.maneuver && !(step.maneuver.type === 'arrive') && (
                       <div class="w-6 h-6 mr-2">
                         {getManeuverIcon(step.maneuver)}
                       </div>
                     )}
                     <div class="flex flex-col">
                       <div class="place text-sm font-medium text-gray-900">
                         {!(step.maneuver.type === 'arrive') ? (step.name !== '' ? step.name : 'Unknown Road') : null}
                       </div>
                       {step.maneuver && step.maneuver.type === 'arrive' ? (
                         <div class="destination-text text-large font-medium text-500 text-center mt-2 ">
                         {step.maneuver.modifier ?  `Destination is on the ${step.maneuver.modifier}`:`Destination reached` }
                       </div>
                       ) : (
                         <div class="flex items-center space-x-2">
                           <div class="text-sm font-medium text-gray-500">{step.distance && formatDistance(step.distance)}</div>
                           <div class="text-sm font-medium text-gray-500">{step.duration && formatDuration(step.duration)}</div>
                         </div>
                       )}
                     </div>
                   </div>
               </div>
              )): ' '}
          </div>
        </div>

      );
      }
    }
    function getManeuverIcon(maneuver) {

      // Handle other maneuvers with existing icons
      switch (maneuver.modifier) {
        case 'left':
          return <FiArrowLeft style={{ fontSize: '16px' }} />;
        case 'right':
          return <FiArrowRight style={{ fontSize: '16px' }} />;
        case 'straight':
          return <FiArrowUp style={{ fontSize: '16px' }} />;
        case 'slight left':
          return <MdTurnSlightLeft style={{ fontSize: '16px' }} />;
        case 'slight right':
          return <MdTurnSlightRight style={{ fontSize: '16px' }} />;
        case 'sharp left':
          return <MdTurnSharpLeft style={{ fontSize: '16px' }} />;
        case 'sharp right':
          return <MdTurnSharpRight style={{ fontSize: '16px' }} />;
        case 'uturn':
          return <MdUTurnLeft style={{ fontSize: '16px' }} />;
        // Handle 'exit rotary'
        case 'exit rotary':
          return <FaLocationArrow style={{ fontSize: '16px' }} />;
        default:
          return null;
      }
    }
    function formatDistance(distance) {
      // Convert to kilometers if over 1000 meters
      const distanceInKm = distance >= 999 ? (distance / 1000).toFixed(1) + ' km' : distance.toFixed(1) + ' meters';
      return distanceInKm;
    }
    function formatDuration(duration) {
      // Convert to minutes if greater than 59 seconds
      const durationInMinutes = duration > 59 ? (Math.round(duration / 60)).toFixed(1) + ' minutes' : duration.toFixed(1) + ' seconds';
      return durationInMinutes;
    }
const mapDispatchToProps = {
    UpdateStepState: update,
 };
function mapStateToProps(state){
  return {
    ...state.search,
    ...state.navigation,
    ...state.directions,
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(MobileView);

