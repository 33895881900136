import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { FaMapMarkerAlt } from "react-icons/fa";

const MapComponent = ({ onMapClick, markerPosition }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    mapRef.current = L.map("mapForm").setView([9.0142, 38.7872], 13);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mapRef.current);

    const handleMapClick = (e) => {
      onMapClick(e.latlng);
    };

    mapRef.current.on("click", handleMapClick);

    return () => {
      mapRef.current.off("click", handleMapClick);
      mapRef.current.remove(); // Ensure the map is properly unmounted
    };
  }, [onMapClick]);

  useEffect(() => {
    if (markerPosition) {
      const myIcon = L.icon({
        iconUrl: `data:image/svg+xml;base64,${btoa(
          renderToStaticMarkup(<FaMapMarkerAlt style={{ color: "red" }} />)
        )}`,
        iconSize: [20, 75],
        iconAnchor: [10, 37.5], // Adjusted icon anchor
        popupAnchor: [0, -15],
      });

      L.marker(markerPosition, { icon: myIcon }).addTo(mapRef.current);
    }
  }, [markerPosition]);

  return <div id="mapForm" style={{ height: "400px" }} />;
};

export default MapComponent;
